<template>
    <v-container>
        <v-alert border="left" color="green" colored-border elevation="2"  v-if="eventDetail.date != ''">
            <v-row align="center"
                justify="center">
                <v-col></v-col>
                <v-col class="text-center" justify-center cols="12" xs="12"  sm="12"  md="6">
                    <v-row>
                        <v-col>
                            <v-icon  color="green">
                                mdi-bell-ring
                            </v-icon>
                            <strong  class="green--text text--lighten-1"> {{eventDetail.date}}</strong >
                            <br/>

                            <h5 class="green--text text--lighten-1">{{eventDetail.eventDetail}}
                                <v-icon  v-if="eventNextDetail.date === ''"  style="margin-top: -0.2rem; width:auto"  @click="handleShowSchedule" color="gray" >
                                    mdi-help-circle
                                </v-icon>
                            </h5 >
                            
                        </v-col>
                        
                    </v-row>
                    <v-divider v-if="eventNextDetail.date != ''" style="margin-top: 0.5rem;"></v-divider>
                    <v-row  style="margin-top: -0.5rem;" v-if="eventNextDetail.date != ''">
                        <v-col>
                            <v-icon  color="gray" style="font-size: 20px;" >
                                mdi-bell-ring
                            </v-icon>
                            <strong  style="font-size: 16px; " class="grey-lighten-5" > {{eventNextDetail.date}}</strong >
                            
                            
                            <h5 class="grey-lighten-2" >  {{eventNextDetail.eventDetail}}
                                <v-icon style="margin-top: -0.2rem;"  @click="handleShowSchedule" color="gray" >
                                    mdi-help-circle
                                </v-icon>
                            </h5 > 
                            
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="text-right">
                
                </v-col>
            </v-row>
        
    </v-alert> 
        
        
        
        <div v-if="termSelected === currentSemester || termSelected === nextSemester || termSelected === yearlySemester">
            <v-row >
                <v-col class="align-self-center text-left black--text">
                    <b>
                        <h1 style="font-family: prompt;">Quick actions</h1>
                    </b>
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="d-inline-flex text-center">
                    <v-dialog
                        v-model="dialog"
                        scrollable
                        max-width="800px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            
                            color="white"
                            light
                            style="width: 100%; height: 97px; border-radius:10px; font-family: prompt;"
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-2"
                        >
                            <v-row>
                                <v-col class="align-center">
                                    <v-card color="#FFF8DE" style="width:80px;height:100%">
                                        <v-card-text>
                                            <v-icon color="#E5C656">mdi-shape-square-plus</v-icon>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col class="text-left">
                                    <b>create</b><br>
                                    <small>(LMS or MS Teams)</small>
                                </v-col>
                            </v-row>
                        </v-btn>

                    </template>
                    <v-card >
                        <v-card-title>
                            <b>
                                CREATE (LMS OR MS TEAMS)
                            </b>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog = false" >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <!-- <v-card-text style="height: 500px;"> -->
                            <v-stepper v-model="e1" alt-labels class="elevation-0">
                                <v-stepper-header class="elevation-0">
                                    <v-stepper-step
                                        :complete="e1 > 1"
                                        step="1"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        สาขาวิชา
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step
                                        :complete="e1 > 2"
                                        step="2"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        รูปแบบการสร้าง
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step 
                                        :complete="e1 > 3"
                                        step="3"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        LMS
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                   
                                    <v-stepper-step 
                                        :complete="e1 > 4"
                                        step="4"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        สรุปการสร้าง
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step 
                                        :complete="e1 > 5"
                                        step="5"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        ผลการตรวจสอบ
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step 
                                        :complete="e1 > 6"
                                        step="6"
                                        color="#745CA8"
                                        style=" font-size: 0.9rem;"
                                    >
                                        ดำเนินการสร้าง
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                <v-stepper-content step="1" >
                                    <v-card
                                        class="mb-12"
                                        max-height="350px"
                                        style="overflow-y: auto"
                                        flat
                                    >
                                            <v-card-title>
                                                <h3>สาขาวิชา</h3>
                                            </v-card-title>
                                            <v-card-text style="padding-left:100px;">
                                                <v-checkbox
                                                    class="mt-0"
                                                    v-model="selectAllCourses"
                                                    label="ทั้งหมด"
                                                    value="AllCourse"
                                                ></v-checkbox>
                                                    <v-expansion-panels flat v-model="panel" multiple v-if="Object.keys(adminSubjection).length > 0">
                                                        <v-expansion-panel
                                                            v-for="(detail, i ,idx) in adminSubjection"
                                                            :key="parseInt(i)"
                                                        >
                                                            <v-checkbox
                                                                class="mt-0"
                                                                v-model="panel"
                                                                :label="i"
                                                                :value="idx"
                                                                style=""
                                                            ></v-checkbox>
                                                            <v-expansion-panel-content >
                                                                <template v-for="(course) in adminSubjection[i]" >
                                                                    <v-checkbox
                                                                        v-model="courseSelected"
                                                                        class="mt-0"
                                                                        :label="`${course.courseNumber} ${course.title}`"
                                                                        :value="course._id"
                                                                        :key="`checkout-${course._id}`"
                                                                        >
                                                                    </v-checkbox>
                                                                    <div class="break" :key="`break-${course._id}`"></div>
                                                                </template>
                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                        </v-card-text>
                                    
                                    </v-card>
                                    <v-row >
                                        <v-col>
                                            <v-btn text @click="e1 = 1">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(2)"
                                            >
                                                Next
                                            </v-btn>
                                        </v-col>
                                    </v-row>     
                                    
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    style="overflow-y: scroll"
                                    flat
                                    >
                                    <v-card-title>
                                        <h3>รูปแบบการสร้าง</h3>
                                    </v-card-title>
                                    <v-card-text style="padding-left:100px;">
                                        <v-radio-group v-model="radioGroupTypeCreate">
                                            <v-radio
                                                :label="`รวม Section`"
                                                :value="2"
                                                selected
                                            ></v-radio>
                                            <v-radio
                                                :label="`แยก Section (เเยกเเต่ละ section ต่อ 1 service)`"
                                                :value="1"
                                                
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-card-text>
                                    </v-card>

                                    <v-row>
                                        <v-col>
                                            <v-btn text @click="e1 = 1">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(3)"
                                            >
                                                Next
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    style="overflow-y: scroll"
                                    flat
                                    >
                                    <v-card-title>
                                        <h3>สร้าง LMS</h3>
                                    </v-card-title>
                                    <v-card-text style="padding-left:100px;">
                                        <v-radio-group v-model="radioGroupTypeLms">
                                            <v-radio
                                                :label="`สร้าง LMS`"
                                                :value="1"
                                            ></v-radio>
                                            <transition name="slide-fade">
                                            <!-- <v-row class="py-5 text-center" v-if="radioGroupTypeLms === 1"> -->
                                            <!-- <v-col>
                                                <v-btn class="btn-lms-fixed-size" color="#F4EBFE">
                                                    <v-img
                                                        alt="moodle-logo"
                                                        src="../../assets/images/moodle-logo.png"
                                                        max-height="100"
                                                        max-width="100"
                                                    ></v-img>
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <v-btn class="btn-lms-fixed-size" color="#DAFFF1">
                                                    <v-img
                                                        alt="mango-logo"
                                                        src="../../assets/images/mango-logo.png"
                                                        max-height="100"
                                                        max-width="100"
                                                    ></v-img>
                                                </v-btn>
                                            </v-col> -->
                                            <!-- </v-row> -->
                                                <v-item-group v-if="radioGroupTypeLms === 1" v-model="selectLmsType">
                                                    <v-container>
                                                    <v-row>
                                                        
                                                        <v-col
                                                        cols="12"
                                                        md="6"
                                                        class="text-center"
                                                        >
                                                        
                                                            <v-checkbox
                                                                v-model="checkBoxMango"  
                                                                >
                                                                <template v-slot:label>
                                                                    <v-img
                                                                        alt="mango-logo"
                                                                        src="../../assets/images/mango-logo.png"
                                                                        max-height="100"
                                                                        max-width="100"
                                                                    ></v-img>
                                                                </template>
                                                            </v-checkbox>
                                                            <!-- <v-item v-slot="{ active, toggle }" value="mango">
                                                                <v-btn 
                                                                class="btn-lms-fixed-size" 
                                                                :color="active ? 'primary' : '#DAFFF1'"
                                                                @click="toggle"
                                                                dark
                                                                >
                                                                <v-img
                                                                    alt="mango-logo"
                                                                    src="../../assets/images/mango-logo.png"
                                                                    max-height="100"
                                                                    max-width="100"
                                                                >
                                                                <v-scroll-y-transition>
                                                                    <div
                                                                    v-if="active"
                                                                    class="text-h2 flex-grow-1 text-center mt-5"
                                                                    >
                                                                    <i class="fas fa-check-circle"></i>
                                                                    </div>
                                                                </v-scroll-y-transition>
                                                                </v-img>
                                                                </v-btn>
                                                            </v-item> -->
                                                        </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-item-group>
                                            </transition>
                                            <v-radio
                                                :label="`ไม่ต้องการ LMS`"
                                                :value="2"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-card-text>
                                    </v-card>

                                    <v-row>
                                        <v-col>
                                            <v-btn text @click="e1 = 2">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(4)"
                                            >
                                                Next
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <!-- <v-stepper-content step="4">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    style="overflow-y: scroll"
                                    flat
                                    >
                                    <v-card-title>
                                        <h3>สร้าง MS Teams</h3>
                                    </v-card-title>
                                    <v-card-text style="padding-left:100px;">
                                        <v-radio-group v-model="radioGroupTypeTeams">
                                            <v-radio
                                                :label="`สร้าง MS Teams`"
                                                :value="1"
                                            ></v-radio>
                                            <v-radio
                                                :label="`ไม่ต้องการ MS Teams`"
                                                :value="2"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-card-text>
                                    </v-card>

                                    <v-row>
                                        <v-col>
                                            <v-btn text @click="e1 = 3">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(5)"
                                            >
                                                Next
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content> -->

                                <v-stepper-content step="4">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    style="overflow-y: scroll"
                                    flat
                                    >
                                    <v-card-title>
                                        <h3>สรุปการสร้าง</h3>
                                    </v-card-title>
                                    <v-card-text style="padding-left:100px;">
                                        <v-row>
                                            <v-col cols="4">
                                                จำนวนวิชาที่ต้องการสร้าง : 
                                            </v-col>
                                            <v-col>
                                                {{courseSelected.length}} วิชา
                                                <v-btn text @click="showFullCourseSelected = !showFullCourseSelected">
                                                    <b style="text-decoration: underline;">
                                                        <a style="color: #745CA8;">
                                                        แสดงวิชา
                                                        </a>
                                                    </b>
                                                    <v-icon v-if="showFullCourseSelected">mdi-chevron-up</v-icon>
                                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <transition name="slide-fade">
                                            <v-row v-if="showFullCourseSelected">
                                                <v-col>
                                                    <v-text-field
                                                        v-model="searchCourseSelected"
                                                        append-icon="mdi-magnify"
                                                        label="Search"
                                                        single-line
                                                        hide-details
                                                        color="#745CA8"
                                                    ></v-text-field>
                                                    <v-data-table
                                                        :headers="headersCourseSelectedConclude"
                                                        :items="fullCourseSelected"
                                                        :items-per-page="5"
                                                        class="elevation-1"
                                                        :search="searchCourseSelected"
                                                    >
                                                    <template v-slot:item.no="{item, index}">
                                                        <tr>
                                                        <td>{{index + 1}}</td>
                                                        </tr>
                                                    </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </transition>
                                        <v-row>
                                            <v-col cols="4">
                                                LMS ที่เลือก :
                                            </v-col>
                                            <v-col v-if="radioGroupTypeLms === 2">
                                                <v-icon color="red" >
                                                    mdi-close-thick 
                                                </v-icon>
                                            </v-col>
                                            <v-col v-else-if="radioGroupTypeLms === 1">
                                                <v-img
                                                    v-if="selectLmsType === 'moodle'"
                                                    alt="moodle-logo"
                                                    src="../../assets/images/moodle-logo.png"
                                                    max-height="50"
                                                    max-width="50"
                                                ></v-img>
                                                <v-img
                                                    v-else-if="selectLmsType === 'mango'"
                                                    alt="mango-logo"
                                                    src="../../assets/images/mango-logo.png"
                                                    max-height="50"
                                                    max-width="50"
                                                ></v-img>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row>
                                            <v-col cols="4">
                                                รูปแบบการสร้าง :
                                            </v-col>
                                            <v-col v-if="radioGroupTypeCreate === 1">
                                                แยก Section
                                            </v-col>
                                            <v-col v-else-if="radioGroupTypeCreate === 2">
                                                รวม Section
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    </v-card>

                                    <v-row>
                                        <v-col>
                                            <v-btn text @click="e1 = 3">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(5)"
                                            >
                                                NEXT
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="5">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    style="overflow-y: scroll"
                                    flat
                                    >
                                    <v-card-title>
                                        <strong class=""><h3>ผลการตรวจสอบ</h3></strong>
                                    </v-card-title>
                                    <v-card-text style="padding-left:100px;">
                                        
                                        <v-card id="" :style=" 'border: 2px solid black; padding:2rem;' " v-if="this.radioGroupTypeLms === 1">
                                            <v-row class="mt-5" v-if="this.selectLmsType === 'moodle'">
                                                <div class="ml-5">  
                                                    <v-img
                                                        alt="service-logo"
                                                        :src="getServiceImage('moodle')"
                                                        max-height="60"
                                                        max-width="80"
                                                    ></v-img>
                                                </div>
                                            </v-row>
                                            <v-row class="mt-5" v-if="this.selectLmsType === 'mango'">
                                                <div class="ml-5">  
                                                    <v-img
                                                        alt="service-logo"
                                                        :src="getServiceImage('mango')"
                                                        max-height="60"
                                                        max-width="80"
                                                    ></v-img>
                                                </div>
                                            </v-row>
                                            <v-row  v-if="this.radioGroupTypeLms === 1">
                                                <v-col cols="6">
                                                    <strong  class="green--text text--bold">จำนวนวิชาที่สามารถสร้างได้ :</strong > 
                                                </v-col>
                                                <v-col>
                                                    {{showLmsCompleteList.length}} รายการ
                                                    <div v-if="showLmsCompleteList.length > 0">
                                                        <v-btn text @click="showLmsComplete = !showLmsComplete" >
                                                            <b style="text-decoration:inline;">
                                                                <a style="color: #745CA8;">
                                                                แสดงวิชา
                                                                </a>
                                                            </b>
                                                            <v-icon v-if="0">mdi-chevron-up</v-icon>
                                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <transition name="slide-fade">
                                                <v-row v-if="showLmsComplete">
                                                    <v-col>
                                                        <v-data-table
                                                            :headers="headersCourseSectionConclude"
                                                            :items="showLmsCompleteList"
                                                            :items-per-page="5"
                                                            class="elevation-1"
                                                        >
                                                        <template v-slot:item.no="{item, index}">
                                                            <tr>
                                                            <td>{{index + 1}}</td>
                                                            </tr>
                                                        </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </transition>
                                            <v-row  v-if="this.radioGroupTypeLms === 1">
                                                <v-col cols="6">
                                                    <strong  class="red--text text--bold">จำนวนวิชาที่ไม่สามารถสร้างได้ :</strong > 
                                                </v-col>
                                                <v-col class="red--text text--bold">
                                                    <string>{{showLmsUnCompleteList.length}} รายการ</string>
                                                    <div v-if="showLmsUnCompleteList.length > 0">
                                                        <v-btn text @click="showLmsUnComplete = !showLmsUnComplete" >
                                                            <b style="text-decoration:inline;">
                                                                <a style="color: #745CA8;">
                                                                แสดงวิชา
                                                                </a>
                                                            </b>
                                                            <v-icon v-if="0">mdi-chevron-up</v-icon>
                                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <transition name="slide-fade">
                                                <v-row v-if="showLmsUnComplete">
                                                    <v-col>
                                                        <v-data-table
                                                            :headers="headersCourseSectionConclude"
                                                            :items="showLmsUnCompleteList"
                                                            :items-per-page="5"
                                                            class="elevation-1"
                                                            style="background-color: rgb(255,125,125)"

                                                        >
                                                        <template v-slot:item.no="{item, index}">
                                                            <tr>
                                                            <td>{{index + 1}}</td>
                                                            </tr>
                                                        </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </transition>
                                        </v-card>
                                        
                                    </v-card-text>
                                    </v-card>
                                    <v-row>
                                        <v-col>
                                            <v-btn text @click="e1 = 4">
                                            Back
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="validateEachStep(6)"
                                            >
                                            Confirm
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                
                                </v-stepper-content>

                                <v-stepper-content step="6">
                                    <v-card
                                    class="mb-12"
                                    max-height="600px"
                                    flat
                                    >
                                    <v-card-text class="text-center">
                                        <!-- <i v-if="createStatus.lms && createStatus.msteams" class="fas fa-hourglass-half fa-5x warning--text"></i>
                                        <v-progress-circular
                                            v-else
                                            :size="70"
                                            :width="7"
                                            color="purple"
                                            indeterminate
                                        ></v-progress-circular>
                                        <h1 class="pt-4">ระบบกำลังดำเนินการ</h1>
                                        <h4 class="pt-3">อาจใช้เวลาสักคู่ในการประมวลผล</h4> -->
                                        <div v-if="!(createStatus.lms && createStatus.msteams)">
                                            <v-progress-circular
                                                :size="70"
                                                :width="7"
                                                color="purple"
                                                indeterminate
                                            ></v-progress-circular>
                                            <h1 class="pt-4">ระบบกำลังสร้างคำขอ</h1>
                                            <h4 class="pt-3">กรุณาเปิดหน้าต่างนี้ทิ้งไว้</h4>
                                        </div>
                                            <transition name="slide-fade">
                                            <div v-if="createStatus.lms && createStatus.msteams">
                                                <i class="fas fa-hourglass-half fa-5x warning--text"></i>
                                                <h1 class="pt-4">ระบบกำลังดำเนินการ</h1>
                                                <h4 class="pt-4">อาจใช้เวลาสักครู่ในการประมวลผล</h4>
                                                <h4 class="pt-1">ท่านสามารถปิดหน้าต่างนี้ได้</h4>
                                            </div>
                                        </transition>
                                    </v-card-text>
                                    </v-card>

                                    <v-row>
                                        <!-- <v-col>
                                            <v-btn text @click="e1 = 3">
                                            Back
                                            </v-btn>
                                        </v-col> -->
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right">
                                            <v-btn
                                                color="primary"
                                                @click="reloadPage()"
                                                v-if="createStatus.lms && createStatus.msteams"
                                            >
                                                Finish
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>
                    </v-card>
                    </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="d-inline-flex text-center">
                    <v-btn
                            
                            color="white"
                            light
                            style="width: 100%; height: 97px;  border-radius:10px; font-family: prompt;"
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-2"
                            @click="routeToAdminManament()"
                        >
                            <v-row>
                                <v-col class="align-center">
                                    <v-card color="#EEFFE8" style="width:80px;height:100%">
                                        <v-card-text>
                                            <v-icon color="#75CA57">mdi-account-plus</v-icon>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col class="text-left">
                                    <b>ผู้ดูแลและหลักสูตร</b><br>
                                    <small>(จัดการผู้ดูแลและหลักสูตร)</small>
                                </v-col>
                            </v-row>
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="d-inline-flex text-center">
                    <v-btn
                        color="white"
                        style="width: 100%; height: 97px;  border-radius:10px; font-family: prompt;"
                        light
                        @click="reloadPage()"
                        class="elevation-2"
                    >
                        <v-row>
                            <v-col class="align-center" >
                                <v-card color="#E8F5FF" style="width:80px;height:100%">
                                    <v-card-text>
                                        <v-icon color="#56A5E2">mdi-refresh</v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="text-left">
                                <b>UPDATE STATUS</b><br>
                                <small>(รายชื่อนักศึกษาที่นำเข้า)</small>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-col>
                <v-specer></v-specer>
            </v-row>
        </div>

       
        <v-row class="pt-4">
            <v-col class="align-self-center text-left black--text">
                    <b>
                        <h1 style="font-family: prompt;">Courses</h1>
                    </b>
            </v-col>
        </v-row>
    
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row>
                        <v-col>
                            <v-select class="mt-5 ml-5"
                                v-model="selectedCourse"
                                :items="ListAllFaculty"
                                item-text="title"
                                label="Please Select Faculty"
                                dense
                                solo
                                
                            >
                            
                            </v-select>
                        </v-col>
                        <v-col ></v-col>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row style="margin-top: -2rem;">
                        <v-col >
                            <v-select
                                v-model="termSelected"
                                :items="itemsSelect"
                                label="เลือกเทอม"
                                id="select-term"
                                dense
                                solo
                                style=" border-color: rgba(192, 0, 250, 0.986); padding: 1rem; width: fit-content; height: fit-content;"
                                v-on:change="onSelectSemester()"
                            ></v-select>
                        
                        </v-col> 
                       
                        
                        <v-col>
                            <v-select
                                v-model="courseSelectedFilter"
                                :items="courseSelectList"
                                label="Please Select Filter"
                                id="select-filter"
                                dense
                                solo
                                chips
                                multiple
                                style=" border-color: rgba(192, 0, 250, 0.986); padding: 1rem; width: fit-content; height: fit-content;"
                                v-on:change="onSelectCourseFilter()"
                            >
                                <template v-slot:selection="{item , index}">
                                    <v-chip v-if="index < 1" >
                                        <span class="text-grey text-caption align-self-center">{{ item.text }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="text-grey text-caption align-self-center">
                                        (+{{courseSelectedFilter.length - 1 }})
                                    </span>

                                </template>
                            </v-select>
                        </v-col>
                        <v-col >
                            <v-text-field
                                color="#745CA8"
                                dense
                                flat
                                solo
                                v-model="search"
                                outlined 
                                prepend-inner-icon="mdi-magnify"
                                label="Search"
                                full-width
                                style=" border-color: rgba(192, 0, 250, 0.986); padding: 1rem; width: fit-content; height: fit-content;"
                            >  
                            </v-text-field>
             
                        </v-col>
                    </v-row>
                    <hr class="ml-5 mr-5">
                    <v-card-text v-if="Object.keys(courseFilter).length > 0">
                        <div v-for="(detail,i) in courseFilter" :key="i" >
                            <h1 class="py-5">{{i}}</h1>
                            <v-data-table
                                :headers="headers"
                                :items="detail"
                                :items-per-page="5"
                                class="elevation-1"
                                :search="search"
                            >
                            <template v-slot:item.status="{item}">
                                <!-- {{getCourseStatus(item)}} -->
                                <v-btn outlined :color="getCourseStatusBtn(item)" x-small>
                                    {{getCourseStatus(item)}}
                                </v-btn>
                            </template>
                            <template v-slot:item.lms="{item}">
                                <v-btn color="#fff" x-small v-if="getOnlyMango(item.mapping.lms).length > 0" class="mr-1">
                                    <v-img
                                        alt="service-logo"
                                        :src="getServiceImage('mango')"
                                        max-height="30"
                                        max-width="40"
                                        class="filter-img"
                                    >
                                    </v-img>
                                </v-btn>
                                <v-btn color="#fff" x-small v-if="getOnlyMoodle(item.mapping.lms).length > 0" class="mr-1">
                                    <v-img
                                        alt="service-logo"
                                        :src="getServiceImage('moodle')"
                                        max-height="30"
                                        max-width="40"
                                        class="filter-img"
                                    >
                                    </v-img>
                                </v-btn>
                                <v-btn color="#fff" x-small v-if="getOnlyCanvas(item.mapping.lms).length > 0" class="mr-1">
                                    <v-img
                                        alt="service-logo"
                                        :src="getServiceImage('canvas')"
                                        max-height="30"
                                        max-width="40"
                                        class="filter-img"
                                    >
                                    </v-img>
                                </v-btn>
                            </template>
                            <template v-slot:item.msteams="{item}">
                                <v-btn color="#fff" x-small v-if="item.mapping.msteams.length > 0" class="mr-1">
                                    <v-img
                                        alt="service-logo"
                                        :src="getServiceImage('msteams')"
                                        max-height="30"
                                        max-width="40"
                                        class="filter-img"
                                    >
                                    </v-img>
                                </v-btn>
                            </template>
                            <template v-slot:item.create>
                                <v-btn outlined color="#745CA8" x-small>
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.edit="{item}">
                                <v-btn color="#745CA8" x-small icon @click="goToCourse(item._id)">
                                    <v-icon>
                                        mdi-cog-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-else>
                        <h2>No data</h2>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="pb-5" v-if="false">
            <v-col v-for="(course, i) in courseLists" :key="i" 	xl="3" lg="4" md="6" sm="6">
                <course-card :regCourseObject="course" :id="`course-card-${i}`" @createTeamsSubmit="onCreateTeamsSubmit" @createLmsSubmit="onCreateLmsSubmit" :key="course._id" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob" ></course-card>
            </v-col>
        </v-row>
        <ModalSchedule :showModal="showSchedule" :dataList="schedule"  @onClose="handleShowSchedule"/>
    </v-container>
    
</template>

<script>
import CourseCard from '../CourseCard'
import apiGatewayCpmService from "@/services/apiGatewayService";
import ModalSchedule from "../ModalNotification/ModalSchedule.vue"
const config = require(`@/config`)
import dataEventCpm from '../../config/dataEventCpm.json'


export default {
    name: 'AdminComponent',
    props: {
        adminSubjection: Object,
        createStatus: Object,
        isAdminCMU: Boolean,
        ListAllFaculty: Array,
    },
    components: {
        CourseCard,
        ModalSchedule
    },
    data: () => ({
        headers: [
            { text: 'Course No.', value: 'courseNumber', width: '20%' },
            { text: 'Course Title', value: 'title', width: '20%' },
            { text: 'Status', value: 'status' , width: '20%'},
            { text: 'LMS', value: 'lms' , width: '16.667%'},
            { text: 'MS Teams', value: 'msteams', width: '16.667%' },
            // { text: 'Create', value: 'create' },
            { text: 'Edit', value: 'edit', width: '16.667%' },
        ],
        headersCourseSelectedConclude: [
            {   
                text: 'No.', 
                align: 'start',
                sortable: false,
                value: 'no',
            },
            { text: 'รหัสวิชา', value: 'courseNumber' },
            { text: 'ชื่อวิชา', value: 'title' },
        ],
        headersCourseSectionConclude: [
            {   text: 'No.',
                value: 'no' ,
                align: 'start',
                sortable: false,
                width: '20%'
            },
            { text: 'รหัสวิชา', value: 'courseNumber',width: '20%' },
            { text: 'ตอน', value: 'sections',width: '40%' },
            { text: 'ชื่อวิชา', value: 'title',width: '20%' },
        ],
        termSelected: config.lmsConfig.currentSemester,
        itemsSelect: [
            {
                text: 'ลงทะเบียนแบบชั้นปี 2567',
                value: '467',
            }, 
            {
                text: 'ปีการศึกษา 1/2567',
                value: '167',
            },
            {
                text: 'ปีการศึกษา 2/2567',
                value: '267',
            },
            {
                text: 'ปีการศึกษา 3/2567',
                value: '367',
            },
        ],
        courseSelectList : [],
        courseSelectedFilter : [] ,
        dialog: false,
        courseFilter : {},
        radioGroupTypeCreate: 2,
        radioGroupTypeLms: 1,
        radioGroupTypeTeams: 2,
        selectLmsType: undefined,
        e1: 1,
        selected: [],
        panel: [],
        courseNotCreate: 0,
        courseSelected: [],
        fullCourseSelected: [],
        search: '',
        searchCourseSelected: '',
        showFullCourseSelected: false,
        resultBeforeCreate:[] , 
        statusCreateReady: false,
        showLmsComplete :false,
        showLmsUnComplete :false,
        showTeamComplete :false,
        showTeamUnComplete :false,
        showLmsCompleteList :[],
        showLmsUnCompleteList :[],
        showTeamCompleteList :[],
        showTeamUnCompleteList :[],
        checkBoxMoodle:false,
        checkBoxMango:false,
        selectedCourse:[],
        queryCourse : {},
        currentSemester: config.lmsConfig.currentSemester,
        nextSemester: config.lmsConfig.nextSemester,
        yearlySemester: config.lmsConfig.yearlySemester,
        filterSelectSubjection:[],
        eventDetail:{
            "date" : "",
            "eventDetail" : ""
        },
        eventNextDetail:{
            "date" : "",
            "eventDetail" : ""
        },
        schedule : dataEventCpm,
        showSchedule : false


    }),
    
    computed: {
 
        selectAllCourses: {
            get: function () {
              return true
            },
            
            set: function (value) {
                let selected = []
                if (value) {
                    for(let i=0;i<Object.keys(this.adminSubjection).length;i++){
                        selected.push(i)
                    }
                }
                this.panel = selected
            }
        },
       
    },
    watch: {
        radioGroupTypeTeams(val , oldval){
            console.log("radioGroupTypeTeams" , val , oldval)
        },
        selected: function(val, oldVal) {
            console.log("select:>" , val,oldVal)
            val = parseInt(val)
            this.panel.push(val)
        },
       
        adminSubjection: function(val, oldVal) {
            this.courseSelectList = []
            for(let i=0; i<Object.keys(this.adminSubjection).length ; i++){
               this.courseSelectList.push({
                    text : Object.keys(this.adminSubjection)[i]
               })
            }
            

            this.courseFilter = val



            console.log("adminSubjection" , val, oldVal)
        },
        isAdminCMU: function(val, oldVal) {
            // console.log("old" , oldVal)
            // console.log("new", val)
            console.log(val, oldVal)
        },
        panel: function(val, oldVal){
            console.log("panel:>" , val,oldVal)
            this.courseSelected = []
            // let intersection = val.filter(x => oldVal.includes(x))
            // let difference = val.filter(x => !oldVal.includes(x))
            let map = [];
            for(let i=0; i<Object.keys(this.adminSubjection).length ; i++){
                map.push(Object.keys(this.adminSubjection)[i])
            }
            // check all course
            // for(let i=0;i<val.length;i++){
            //     for(let j=0;j<this.adminSubjection[map[val[i]]].length;j++){
            //         this.courseSelected.push(this.adminSubjection[map[val[i]]][j]._id)
            //     }
            // }
            
        },
        selectedCourse :function(val, oldVal){
            if(val){
                var result = this.ListAllFaculty.find(item => item.title === val);
                this.queryCourse = result
                // console.log(" this.queryCourse " ,this.queryCourse )
                this.onSelectFac()
            }
            console.log(val,oldVal)
        },
        // termSelected : function(val){
        //     this.onSelectSemester(val)
        // },
        courseSelected: function(val, oldVal){
            console.log(val,oldVal)
        },
        selectLmsType: function(val, oldVal){
            console.log(val,oldVal)
        },
        checkBoxMango:function(val, oldVal){
            console.log(val,oldVal)
            if(val){
                this.selectLmsType = "mango"
                this.checkBoxMoodle = false
            }
        },
        checkBoxMoodle:function(val, oldVal){
            console.log(val,oldVal)
            if(val){
                this.selectLmsType = "moodle"
                this.checkBoxMango = false
            }
        },

    },
    created() {
        console.log("create :>" , this.ListAllFaculty)
        // let _courseSelectList = []
       
        this.checkDate()
    },
    mounted(){
    },
    methods: {
        handleShowSchedule(){
            if(this.showSchedule){
                this.showSchedule = false
            }else{
                this.showSchedule = true
            }
        },
        checkDate(){
            for(let i = 0 ; i < this.schedule.length ; i++ ){
                
                for(let j = 0 ; j < this.schedule[i].termListEvent.length ; j ++){
                    let beforeDateEvent = this.schedule[i].termListEvent[j].dateStart* 1000
                    if(new Date().setDate(new Date().getDate() + 7) >= beforeDateEvent ){
                        if(this.schedule[i].termListEvent[j].dateEnd !== null){
                            if(this.schedule[i].termListEvent[j].dateEnd*1000 > new Date().setDate(new Date().getDate() ) ){
                                this.eventDetail.date =   " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j].dateStr 
                                this.eventDetail.eventDetail = this.schedule[i].termListEvent[j].event
                                try{
                                    this.eventNextDetail.date =  " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j+1].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i].termListEvent[j+1].event
                                }catch{
                                    this.eventNextDetail.date =  " (" + this.schedule[i+1].termId + ") " + this.schedule[i+1].termListEvent[0].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i+1].termListEvent[0].event
                                }
                                console.log("date event :>" , this.eventDetail.date , this.eventDetail.eventDetail)

                            }
                        }else{
                            if(beforeDateEvent >= new Date().setDate(new Date().getDate())  ){
                                this.eventDetail.date =   " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j].dateStr 
                                this.eventDetail.eventDetail = this.schedule[i].termListEvent[j].event
                                try{
                                    this.eventNextDetail.date =  " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j+1].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i].termListEvent[j+1].event
                                }catch{
                                    this.eventNextDetail.date =  " (" + this.schedule[i+1].termId + ") " + this.schedule[i+1].termListEvent[0].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i+1].termListEvent[0].event
                                }
                                break
                            }
                        }
                        
                    }
                }
                if( this.eventDetail.date != ""){
                    break
                }
            }
        },
        reloadPage(){
            window.location.reload()
        },

        getOnlyMango (mappingArr) {
            return mappingArr.filter(i => i.service === 'mango')
        },
        getOnlyMoodle (mappingArr) {
            return mappingArr.filter(i => i.service === 'moodle')
        },
        getOnlyCanvas (mappingArr) {
            return mappingArr.filter(i => i.service === 'canvas')
        },
        onCreateTeamsSubmit (sectionArr,courseid) {
            this.$emit('createTeamsSubmit', sectionArr, courseid)
        },
        onCreateLmsSubmit (createData,courseid) {
            console.log(createData,courseid)
            this.$emit('createLmsSubmit', createData, courseid)
        },
        onCheckTeamJob (courseId, mappingId) {
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        onCheckLmsJob (courseId, mappingId) {
            this.$emit('checkLmsJob', courseId, mappingId)
        },
        onSelectFac(){
        
            this.$emit('onSelectFac' , this.queryCourse)
        },
        onSelectSemester(){
            this.$emit('onSelectSemester' , this.termSelected)
        },
        
        onSelectCourseFilter(){
            this.courseFilter  = Object.fromEntries(Object.entries(this.adminSubjection).filter(([key]) => this.courseSelectedFilter.includes(key)))
            this.showSchedule = false
        },
        clickCard () {
            console.log("click card")
        },
        routeToAdminManament(){
            this.$router.push('admin-management')
        },
        async validateEachStep (nextStep) {
            let isCanBeNext = false
            // console.log("validateEachStep:",nextStep)
            switch (nextStep) {
                case 1:
                    isCanBeNext = true
                    break
                case 2:
                    // console.log(this.courseSelected.length)
                    if(this.courseSelected.length > 0){
                        isCanBeNext = true
                    }
                    break
                case 3:
                    if(this.radioGroupTypeCreate > 0){
                        isCanBeNext = true
                    }
                    break
                // case 4:
                //     if(this.radioGroupTypeLms === 1 && this.selectLmsType !== undefined){
                //         isCanBeNext = true
                //     }else if(this.radioGroupTypeLms === 2){
                //         isCanBeNext = true
                //     }
                //     break
                case 4:
                    await this.createCourseSelectedList()
                    if( this.radioGroupTypeLms){
                        isCanBeNext = true
                    }
                    break
                case 5:
                    await this.CheckCreateData();
                    if( this.statusCreateReady){
                        isCanBeNext = true
                        this.statusCreateReady = false;
                        let tmp = this.resultBeforeCreate.lms.canCreate;
                        // console.log("showLmsCompleteList",tmp)
                        for(let i= 0; i < this.resultBeforeCreate.lms.canCreate.length ; i++ ){
                            for(let j= 0; j < this.resultBeforeCreate.lms.canCreate[i].sections.length ; j++ ){
                                tmp["sections"] = this.resultBeforeCreate.lms.canCreate[i].sections[j]
                            }
                        }
                        this.showLmsCompleteList = tmp;
                        // console.log("showLmsCompleteList",tmp)
                        tmp = this.resultBeforeCreate.lms.canNotCreate;
                        // console.log("showLmsCompleteList",tmp)
                        for(let i= 0; i < this.resultBeforeCreate.lms.canNotCreate.length ; i++ ){
                            for(let j= 0; j < this.resultBeforeCreate.lms.canNotCreate[i].sections.length ; j++ ){
                                tmp["sections"] = this.resultBeforeCreate.lms.canNotCreate[i].sections[j]
                            }
                        }
                        this.showLmsUnCompleteList = tmp;

                        tmp = this.resultBeforeCreate.team.canCreate;
                        for(let i= 0; i < this.resultBeforeCreate.team.canCreate.length ; i++ ){
                            for(let j= 0; j < this.resultBeforeCreate.team.canCreate[i].sections.length ; j++ ){
                                tmp["sections"] = this.resultBeforeCreate.team.canCreate[i].sections[j]
                            }
                        }
                        this.showTeamCompleteList = tmp
                        tmp = this.resultBeforeCreate.team.canNotCreate;
                        for(let i= 0; i < this.resultBeforeCreate.team.canNotCreate.length ; i++ ){
                            for(let j= 0; j < this.resultBeforeCreate.team.canNotCreate[i].sections.length ; j++ ){
                                tmp["sections"] = this.resultBeforeCreate.team.canNotCreate[i].sections[j]
                            }
                        }
                        this.showTeamUnCompleteList = tmp
                    }
                    isCanBeNext = true;
                    break
                case 6:
                    
                    if(this.showTeamCompleteList.length  === 0 && this.showLmsCompleteList.length  === 0 ){
                        isCanBeNext = false
                    }else{
                        await this.createServiceByAdmin()
                        isCanBeNext = true
                    }
                    break
                default:
                    isCanBeNext = false
            }
            if(isCanBeNext){
                this.e1 = nextStep
            }
        },
        getServiceImage (service) {
          let images = ""
          switch (service) {
            case "msteams":
              images = require.context('../../assets/images', false, /\.svg$/)
              return images('./' + 'microsoft-teams-logo-crop' + ".svg")
            case "canvas":
              images = require.context('../../assets/images', false, /\.png$/)
              return images('./' + 'canvas-logo-crop' + ".png")
            case "moodle":
              images = require.context('../../assets/images', false, /\.png$/)
              return images('./' + 'moodle-logo-crop' + ".png")
            case "mango":
              images = require.context('../../assets/images', false, /\.png$/)
              return images('./' + 'mango-logo-crop' + ".png")
            default:
              break;
          }
        },
        goToCourse (courseId) {
            window.open(`/courses/${courseId}`,'_blank')
        },
        getCourseStatus (rowData) {
            // console.log("rowData" , rowData.courseNumber)
            let courseStatus = {
                lms: "Completed",
                msteams: "Completed",
                all: "Completed"
            }
            let intersect = "";
            let intersectList = [];
            let count_created = 0;
            let count_synced = 0;

            for(let i=0;i<rowData.mapping.lms.length;i++){
                intersectList.push(rowData.mapping.lms[i].jobStatus);
                switch (rowData.mapping.lms[i].jobStatus) {
                    case 'created':
                        count_created++;
                        break
                    case 'synced':
                        count_synced++;
                        break
                    case 'error':
                        break
                    default:
                        break
                }
            }

            if(rowData.mapping.lms.length > 0 ){
                if( intersectList.includes('error')){
                    intersect = "error"
                }else if(count_created + count_synced >= rowData.mapping.lms.length){
                    intersect = "created"
                }else {
                    intersect = "inprogress"
                }  
            }else{
                intersect = "unknown"
            }
          
            switch (intersect) {
                case 'created':
                    courseStatus.lms = "Completed"
                    break
                case 'synced':
                    courseStatus.lms = "Completed"
                    break
                case 'unknown':
                    courseStatus.lms = "No Service"
                    break
                case 'error':
                    courseStatus.lms = "Error"
                    break
                default:
                    courseStatus.lms = "Inprogress"
            }

            let intersect_msteam = "";
            intersectList = [];
            count_created = 0;
            count_synced = 0;
            for(let i=0;i<rowData.mapping.msteams.length;i++){
                intersectList.push(rowData.mapping.msteams[i].jobStatus);
                switch (rowData.mapping.msteams[i].jobStatus) {
                    case 'created':
                        count_created++;
                        break
                    case 'synced':
                        count_synced++;
                        break
                    default:
                        break
                }
            }

            if(rowData.mapping.msteams.length > 0 ){
                if( intersectList.includes('error')){
                    intersect_msteam = "error"
                }else if(count_created + count_synced  >= rowData.mapping.msteams.length){
                    intersect_msteam = "created"
                }else {
                    intersect_msteam = "inprogress"
                }  
            }else{
                intersect_msteam = "unknown"
            }
          
            switch (intersect_msteam) {
                case 'created':
                    courseStatus.msteams = "Completed"
                    break
                case 'synced':
                    courseStatus.msteams = "Completed"
                    break
                case 'unknown':
                    courseStatus.msteams = "Unknown"
                    break
                case 'error':
                    courseStatus.msteams = "Error"
                    break
                default:
                    courseStatus.msteams = "Inprogress"
            }

            if(courseStatus.lms === courseStatus.msteams){
                courseStatus.all = courseStatus.lms
            }else if(courseStatus.lms === "Error" || courseStatus.msteams === "Error"){
                courseStatus.all = "Error"
            }else if(courseStatus.lms === "Inprogress" || courseStatus.msteams === "Inprogress"){
                courseStatus.all = "Inprogress"
            }else if(courseStatus.lms === "Unknown"){
                courseStatus.all = courseStatus.msteams
            }else if(courseStatus.msteams === "Unknown"){
                courseStatus.all = courseStatus.lms
            }else{
                courseStatus.all = "Completed"
            }
            return courseStatus.all
        },
        getCourseStatusBtn (rowData) {
            // return "primary"   
            let status = this.getCourseStatus(rowData)
            switch (status) {
                case "Completed":
                    return "success"           
                case "Inprogress":
                    return "warning"  
                case "Error":
                    return "error"   
                default:
                    return "primary"   
            }
        },
        observerClean (obj) {
            return Object.keys(obj).reduce(
                (res, e) => Object.assign(res, { [e]: obj[e] }),
                {}
            )
        },
        async createServiceByAdmin () {
            let courseList = []
            for(let i = 0 ; i <  this.fullCourseSelected.length ; i++){
                courseList.push(this.fullCourseSelected[i]._id)

            }
            let createData = {}
            if(this.termSelected === this.currentSemester){
                createData = {
                    "mode":"addMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mango.termId,
                        "termDateStart": config.lmsConfig.moodle.termStart ,
                        "termDateEnd": config.lmsConfig.moodle.termEnd
                    },
                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
            }else if(this.termSelected === this.nextSemester){
                createData = {
                    "mode":"addMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mangoNext.termId,
                        "termDateStart": config.lmsConfig.moodleNext.termStart ,
                        "termDateEnd": config.lmsConfig.moodleNext.termEnd
                    },

                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
                
            }else if(this.termSelected === this.yearlySemester){
                createData = {
                    "mode":"addMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mangoYearly.termId,
                        "termDateStart": config.lmsConfig.moodleYearly.termStart ,
                        "termDateEnd": config.lmsConfig.moodleYearly.termEnd
                    },

                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
                
            }            
            this.$emit('modalAdminSubmit', createData )
        },
        async CheckCreateData(){
            const profile = await apiGatewayCpmService.getProfile(localStorage.getItem("accessToken"))
            let courseList = []
            for(let i = 0 ; i <  this.fullCourseSelected.length ; i++){
                courseList.push(this.fullCourseSelected[i]._id)

            }
            let createData = {}
            if(this.termSelected === this.currentSemester){
                createData = {
                    "mode":"checkAddMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mango.termId,
                        "termDateStart": config.lmsConfig.moodle.termStart ,
                        "termDateEnd": config.lmsConfig.moodle.termEnd
                    },
                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
            }else if(this.termSelected === this.nextSemester){
                createData = {
                    "mode":"checkAddMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mangoNext.termId,
                        "termDateStart": config.lmsConfig.moodleNext.termStart ,
                        "termDateEnd": config.lmsConfig.moodleNext.termEnd
                    },

                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
                
            }else if(this.termSelected === this.yearlySemester){
                createData = {
                    "mode":"checkAddMultipleService",
                    "courseIdList": courseList,
                    "addTeams": this.radioGroupTypeTeams === 1 ? true : false,
                    "addLms": this.radioGroupTypeLms === 1 ? true : false,
                    "lmsType": this.selectLmsType,
                    "additionalData":{
                        "termId": config.lmsConfig.mangoYearly.termId,
                        "termDateStart": config.lmsConfig.moodleYearly.termStart ,
                        "termDateEnd": config.lmsConfig.moodleYearly.termEnd
                    },

                    createType: this.radioGroupTypeCreate === 1 ? 'manyToMany':'manyToOne',
                }
            }       
            let preData ={
                ...createData,
                "tagName":"admin:"+String(profile.email)
            }
            console.log("postJobs;>" ,preData)
            
            const response = await apiGatewayCpmService.apiPostJobs(localStorage.getItem("accessToken") , preData);
            if(response.status){
                delete response.status;
                this.resultBeforeCreate = response;
                this.statusCreateReady = true;
                console.log("this.resultBeforeCreate:>" , this.resultBeforeCreate)
                // return true;
            }
            // return false;

        },
        async createCourseSelectedList () {
            const courseSelected = this.courseSelected
            const adminSubjection = this.adminSubjection
            let fullCourseSelected = []
            let found = false
            for(let i=0;i<courseSelected.length;i++){
                found = false
                for(let j=0;j<Object.keys(adminSubjection).length;j++){
                    for(let k=0;k<adminSubjection[Object.keys(adminSubjection)[j]].length;k++){
                        if(courseSelected[i] ===adminSubjection[Object.keys(adminSubjection)[j]][k]._id){
                            fullCourseSelected.push(adminSubjection[Object.keys(adminSubjection)[j]][k])
                            found = true
                            break
                        }
                    }
                    if(found) break
                }
            }
            console.log("createCourseSelectedList:",fullCourseSelected)
            this.fullCourseSelected = fullCourseSelected
        }
    },
}
</script>